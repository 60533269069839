import React, {Component} from "react";
import {render} from "react-dom";
import { HashRouter, Route, Switch} from "react-router-dom";
import {theme} from "./theme";
import {Interactions, Targets, Publications, NotFound, About, Drugs, Access, ClinicalTrials,Submit,Disclosure} from "./pages";
import {Box, Grid, Grommet, Main, Text} from 'grommet';
import Container from '@material-ui/core/Container';
import Divider from "@material-ui/core/Divider";
import {FaTwitter, FaLaptop,FaEnvelopeOpenText} from 'react-icons/fa/index.esm';
import {Header} from "./Header";
import Button from "@material-ui/core/Button";
import Sidebar from "./components/Sidebar";
import {Publication, Drug, Target, Interaction, Label,  ClinicalTrial} from "./icons";
import {LoadingBar} from "./LoadingBar";


//import whyDidYouRender from "@welldone-software/why-did-you-render";
/*if (process.env.NODE_ENV === 'development') {
    const whyDidYouRender = require('@welldone-software/why-did-you-render');
    whyDidYouRender(React, {
        trackAllPureComponents: true,
    });
}*/

//todo icons to const
// UpperCase first letter
//Design adjusting windowsize
//highlight first column



const items = [
    {
        name: "  About",
        icon: Label,
        pathto: "/",
        value: 1,
    },
    {
        name: "  Interactions",
        icon: Interaction,
        pathto: "/interactions",
        value: 2,
    },
    {
        name: "  Targets",
        icon: Target,
        pathto: "/targets",
        value: 3,
    },

    {
        name: "  Drugs",
        icon: Drug,
        pathto: "/drugs",
        value: 4,
    },

    {
        name: "  Publications",
        icon: Publication,
        pathto: "/publications",
        value: 5,
    },
    {
        name: "  Clinical Trials",
        icon: ClinicalTrial,
        pathto: "/clinicaltrials",
        value: 6,
    },

    {
        name: "  Submit",
        icon: FaEnvelopeOpenText,
        pathto: "/submit",
        value: 7,
    },
    {
        name: "  API",
        icon: FaLaptop,
        pathto: "/access",
        value: 8,
    }

];



class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            publicationData: [],
            targetData: [],
            drugData: [],
            interactionData: [],
            loading: true
        };
    }

    //state = { loading: true };

    componentDidMount() {
        let url = require("./data/url");
        Promise.all([
            fetch(url.publications),
            fetch(url.targets),
            fetch(url.drugs),
            fetch(url.interactions),
            fetch(url.clinical),
            fetch(url.researches)

        ])
            .then(([res1, res2, res3, res4, res5,res6]) => Promise.all([res1.json(), res2.json(), res3.json(), res4.json(), res5.json(), res6.json()]))
            .then(([publicationData, targetData, drugData, interactionData, clinicalData, researchData]) => this.setState({
                publicationData: publicationData.data,
                targetData: targetData.data,
                drugData: drugData.data,
                interactionData: interactionData.data,
                clinicalData: clinicalData.data,
                researchData: researchData.data,
                loading:false
            })).catch(error => console.log(`Error in promises ${error}`))
    }

    render() {
        const {publicationData, targetData, drugData, interactionData, clinicalData,researchData,loading} = this.state;
        return (


            <Grommet theme={theme} full>
                <Grid fill rows={["auto", "flex", "auto"]}>
                    <Header/>
                    <Divider/>
                    {/*fixed footer*/}
                    <Box>

                        <Grommet theme={theme} full>
                            <Grid fill columns={["auto", "flex", "auto"]}>


                                <HashRouter>

                                    <Box direction="row" full justify={"start"} align="start" background={"white"}>
                                        <Sidebar items={items}/>

                                    </Box>

                                    <Main>
                                        {loading ?      <LoadingBar/> :
                                        <Container fixed>

                                            <Switch>

                                                    <Route path="/" exact component={About}/>

                                                    <Route path="/access" component={Access}/>

                                                    <Route path="/submit" component={Submit}/>

                                                    <Route path="/interactions">
                                                        <Interactions
                                                            data={interactionData}/>

                                                    </Route>

                                                    <Route path="/targets">
                                                        <Targets
                                                            data={targetData}/>
                                                    </Route>

                                                    <Route path="/drugs">
                                                        <Drugs
                                                            data={drugData}
                                                            //pub={publicationData}
                                                            //inter={interactionData}
                                                        />
                                                    </Route>

                                                    <Route path="/publications">
                                                        <Publications
                                                            researches={researchData}
                                                            data={publicationData}/>

                                                    </Route>

                                                    <Route path="/clinicaltrials">
                                                        <ClinicalTrials
                                                            data={clinicalData}/>
                                                    </Route>

                                                <Route path="/disclosure">
                                                    <Disclosure/>
                                                </Route>

                                                    <Route component={NotFound}/>

                                                </Switch>

                                        </Container>}
                                    </Main>
                                </HashRouter>
                            </Grid>

                        </Grommet>


                    </Box>


                    <Box tag="footer" pad="xxsmall" background={"dark-1"}>
                       <Box style={{flex: 1, flexDirection: 'row', justifyContent: 'space-between'}}>
                            <Button href="https://twitter.com/heiderlab?lang=de" rel="noopener noreferrer"
                                    target="_blank" color="primary">
                                <FaTwitter size={24}/>

                            </Button>

                           <Text style={{ color: "primary"}}>{"\t"} <a href="https://www.medizin.uni-muenster.de/en/imi/site-notice.html"> Disclosure</a>
                           </Text>

                           <Text style={{ color: "primary"}}>{"\t"} <a href="https://www.medizin.uni-muenster.de/en/faculty-of-medicine/dataprotection.html"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"> Privacy Statement</a>
                           </Text>


                           <Text color="#76B7B2" style={{paddingRight:10}}> v1.1.1 </Text>
                        </Box>
                    </Box>


                </Grid>
            </Grommet>
        );
    }
}

render(<App/>, document.getElementById("root"));
