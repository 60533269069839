import React, {Component} from "react";
import {Box, Heading} from "grommet";
import {DetailTable} from "../../content/";
import LinkedElement from "../description/LinkedElement";
import {LoadingBar} from "../../../LoadingBar";



export class PublicationsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pubData: [],
            loading: true,
        };
    }

    componentDidMount() {
        const {url} = this.props;
        fetch(url)
            .then(response => response.json())
            .then(pubData => this.setState({pubData: pubData["publications"],
            loading:false
    }));

    }

    render() {

        const {pubData,loading} = this.state;
        const {heading} = this.props;
        //const {data} = this.props;
        console.log(pubData);
        return (

            <Box fill align="start" justify="center" background={"#fafafa"} pad="small">
                <Box fill align="start" justify="center">
                    <Heading
                        color={"#76B7B2"} level={2}>
                        {heading}: Related publications
                    </Heading>


                </Box>

                <Box fill align="center" justify="center">

                    {loading ?      <LoadingBar/> :
                    <DetailTable
                        onRowClick={(event, rowData, togglePanel) => togglePanel()}
                        data={pubData}

                        columns={
                            [
                                {title: 'Title', field: 'name', defaultSort: "asc"},
                                {title: 'Author', field: "author"},
                                {title: 'Source', field: 'database'},
                            ]

                        }
                        detailPanel={[
                            {
                                tooltip: 'Show Name',
                                render: rowData => {
                                    return (
                                        <Box justify="center" pad="medium">
                                            <LinkedElement
                                                name={"doi"}
                                                id={rowData.doi}
                                                link={"https://doi.org/"}
                                            />


                                            <span>&nbsp;&nbsp;</span>
                                            <h2>
                                                Abstract
                                            </h2>
                                            {rowData.abstract}
                                            <span>&nbsp;&nbsp;</span>
                                        </Box>
                                    )
                                }
                            }]}


                    >
                    </DetailTable>}
                    <span>&nbsp;&nbsp;</span>
                </Box>
            </Box>

        );
    }
}