import React from "react";
import {Box, Heading, Text} from "grommet";
import {Drug, Interaction, Publication, Target} from "../icons";


const size = 20;
const brand = "#76B7B2";

export const Submit = () => (
    <Box fill align="start">
        <Heading>Submit</Heading>

        <Text style={{fontSize: size}}>Submissions are closed. </Text>
        
    </Box>
);
